import Chat from "@mui/icons-material/Chat";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import type { ChangeBanStatus } from "../../../api";
import classes from "./AccountActions.module.css";
import {
  AccountActionsBan,
  AccountActionsDecks,
  AccountActionsEdit,
  AccountActionsMultiAccount,
} from "./AccountActionsOptions";
import type { AccountRow } from "./AccountRow";

interface AccountActionsProps {
  account: AccountRow;
  onBanStatusChange: (changeBanStatus: ChangeBanStatus) => Promise<void>;
  onChange: (account: AccountRow) => Promise<void>;
  onDelete: (account: AccountRow) => Promise<void>;
  onWipe: (account: AccountRow, reason: string) => Promise<void>;
}

export const AccountActions = (props: AccountActionsProps) => {
  const { account, onBanStatusChange, onChange, onDelete, onWipe } = props;
  const { t } = useTranslation();

  return (
    <span className={classes.root}>
      <AccountActionsEdit
        account={account}
        onChange={onChange}
        onDelete={onDelete}
        onWipe={onWipe}
      />
      <AccountActionsBan
        account={account}
        onBanStatusChange={onBanStatusChange}
      />
      <AccountActionsMultiAccount account={account} />
      <AccountActionsDecks account={account} />
      <Tooltip title={t("AccountActions.goToChats")}>
        <IconButton
          component={Link}
          href={`/chats?account=${account.id}`}
          size="small"
        >
          <Chat />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("AccountActions.goToTransactions")}>
        <IconButton
          component={Link}
          href={`/transactions?account=${account.id}`}
          size="small"
        >
          <CurrencyExchange />
        </IconButton>
      </Tooltip>
    </span>
  );
};
