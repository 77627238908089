import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Gold.module.css";
import { GoldIcon } from "./GoldIcon";

interface GoldProps {
  fullWidth?: boolean;
  value: number | null | undefined;
}

export const Gold = (props: GoldProps) => {
  const { fullWidth, value } = props;

  const { t } = useTranslation();
  const formattedValue = useMemo(() => {
    if (typeof value === "undefined" || value === null) {
      return undefined;
    } else {
      return String(t("Gold.value", { value }));
    }
  }, [t, value]);

  return (
    <span className={clsx(classes.root, { [classes.fullWidth]: fullWidth })}>
      {typeof formattedValue === "undefined" ? (
        <span>&mdash;</span>
      ) : (
        <span className={classes.label} title={formattedValue}>
          {formattedValue}&nbsp;
        </span>
      )}
      <GoldIcon />
    </span>
  );
};
