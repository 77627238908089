/**
 * Checks whether `subset` is a valid subset of `set`.
 */
export function isSubsetOf<T>(subset: Set<T>, set: Set<T>) {
  for (const value of subset) {
    if (!set.has(value)) {
      return false;
    }
  }

  return true;
}
