import { format as formatDate } from "date-fns";
import {
  de as dateLocaleDe,
  enGB as dateLocaleEnGB,
  enUS as dateLocaleEnUS,
} from "date-fns/locale";
import i18next from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import i18nextHttpBackend from "i18next-http-backend";
import numbro from "numbro";
import numbroLanguagesDe from "numbro/languages/de-DE";
// @ts-ignore
import numbroLanguagesEnGB from "numbro/languages/en-GB";
import { initReactI18next } from "react-i18next";
import { assertNever } from "./lib/utils";

export enum Language {
  EnglishGB = "en-GB",
  EnglishUS = "en-US",
  German = "de-DE",
}

export const languages = Object.values(Language).sort() as Language[];

export const getCountry = (language: Language) => {
  return language.split("-")[1]!.toLowerCase();
};

export const getLanguage = (language: Language) => {
  return language.split("-")[0]!.toLowerCase();
};

export const getName = (language: Language) => {
  switch (language) {
    case Language.EnglishGB:
      return "English (GB)";
    case Language.EnglishUS:
      return "English (US)";
    case Language.German:
      return "Deutsch";
    default:
      return assertNever(language);
  }
};

numbro.registerLanguage(numbroLanguagesDe);
numbro.registerLanguage(numbroLanguagesEnGB);

let dateLocale = dateLocaleEnUS;

if (typeof window !== "undefined") {
  i18next
    .use(i18nextBrowserLanguagedetector)
    .use(i18nextHttpBackend)
    .use(initReactI18next)
    // all options: https://www.i18next.com/overview/configuration-options
    .init({
      backend: {
        loadPath: (languages: string[], namespaces: string[]) =>
          `/locales/${getLanguage(languages[0] as Language)}/${
            namespaces[0]
          }.json`,
      },
      debug: process.env.NODE_ENV !== "production",
      fallbackLng: Language.EnglishUS,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: (value: unknown, format): string => {
          if (typeof value === "number") {
            return numbro(value).format(format);
          } else if (typeof value === "object" && value instanceof Date) {
            return formatDate(value, format ?? "P, pp", { locale: dateLocale });
          }

          return String(value);
        },
      },
      load: "currentOnly",
      supportedLngs: languages,
    })
    .catch((error) => {
      console.error(error);
    });
}

i18next.on("languageChanged", (language: Language) => {
  switch (language) {
    case Language.EnglishGB:
      dateLocale = dateLocaleEnGB;
      numbro.setLanguage("en-GB");
      break;
    case Language.EnglishUS:
      dateLocale = dateLocaleEnUS;
      numbro.setLanguage("en-US");
      break;
    case Language.German:
      dateLocale = dateLocaleDe;
      numbro.setLanguage("de-DE");
      break;
    default:
      throw assertNever(language);
  }
});

export default i18next;

export function isI18nProvided(
  i18n: {} | typeof i18next,
): i18n is typeof i18next {
  return typeof (i18n as typeof i18next).t !== "undefined";
}
