import type { UpdateUseLocalStorageEventInit } from "./UpdateUseLocalStorageEventInit";

export function setLocalStorage(
  key: string,
  newValue: string | undefined,
): void {
  if (typeof newValue === "undefined") {
    // eslint-disable-next-line no-restricted-globals
    localStorage.removeItem(key);
  } else {
    // eslint-disable-next-line no-restricted-globals
    localStorage.setItem(key, newValue);
  }

  window.dispatchEvent(
    new CustomEvent<UpdateUseLocalStorageEventInit>("updateUseLocalStorage", {
      detail: {
        key,
        newValue,
      },
    }),
  );
}
