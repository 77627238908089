import RestartAlt from "@mui/icons-material/RestartAlt";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useCallback, useLayoutEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import type { AccountRow } from "../AccountRow";
import { AccountActionsConfirmDialog } from "./AccountActionsConfirmDialog";

interface AccountActionsWipeProps {
  account: AccountRow;
  onClose: () => void;
  onWipe: (account: AccountRow, reason: string) => Promise<void>;
  open: boolean;
}

export const AccountActionsWipe = (props: AccountActionsWipeProps) => {
  const { account, onClose, onWipe, open } = props;
  const { t } = useTranslation();

  const { name } = account;

  const [reason, setReason] = useState("");

  useLayoutEffect(() => {
    if (open) {
      setReason("");
    }
  }, [open]);

  const handleReasonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setReason(event.currentTarget.value);
    },
    [],
  );

  const handleConfirm = useCallback(
    async (account: AccountRow) => {
      return onWipe(account, reason);
    },
    [reason, onWipe],
  );

  return (
    <AccountActionsConfirmDialog
      account={account}
      confirmIcon={<RestartAlt />}
      locals={{
        cancel: t("AccountActionsWipe.cancel"),
        confirm: t("AccountActionsWipe.confirm"),
        confirmName: t("AccountActionsWipe.confirmName"),
        info: t("AccountActionsWipe.info"),
        title: t("AccountActionsWipe.title"),
      }}
      onClose={onClose}
      onConfirm={handleConfirm}
      open={open}
    >
      <Typography>
        <Trans
          components={{ b: <strong /> }}
          i18nKey="AccountActionsWipe.info"
          values={{ name }}
        />
      </Typography>
      <Alert severity="error">
        {t("AccountActionsWipe.warningIrreversible")}
      </Alert>
      <TextField
        autoFocus
        fullWidth
        label={t("AccountActionsWipe.reason")}
        margin="dense"
        onChange={handleReasonChange}
        value={reason}
      />
    </AccountActionsConfirmDialog>
  );
};
