import ColorLens from "@mui/icons-material/ColorLens";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { assertNever } from "../../lib/utils";
import { Theme, useStoredTheme } from "../../store/theme";

function nextTheme(theme: Theme): Theme {
  switch (theme) {
    case Theme.System:
      return Theme.Dark;
    case Theme.Dark:
      return Theme.Light;
    case Theme.Light:
      return Theme.System;
    default:
      return assertNever(theme);
  }
}

export const ThemePicker = () => {
  const { t } = useTranslation();
  const [theme, setTheme] = useStoredTheme();

  const handleClick = useCallback(() => {
    setTheme(nextTheme(theme));
  }, [theme, setTheme]);

  const style = useMemo(
    () => ({
      opacity: theme === Theme.System ? 0.5 : undefined,
    }),
    [theme],
  );

  return (
    <Tooltip title={t("ThemePicker.changeTheme") as string}>
      <IconButton onClick={handleClick} style={style}>
        <ColorLens />
      </IconButton>
    </Tooltip>
  );
};
