import { useTranslation } from "react-i18next";
import classes from "./NumberFormatter.module.css";

interface NumberFormatterProps {
  value: number | null | undefined;
}

export const NumberFormatter = (props: NumberFormatterProps) => {
  const { value } = props;
  const { t } = useTranslation();

  if (typeof value === "undefined" || value === null) {
    return <span className={classes.root}>&mdash;</span>;
  } else {
    const formattedValue = t("General.number", { value });

    return (
      <span className={classes.root} title={formattedValue}>
        {formattedValue}
      </span>
    );
  }
};
