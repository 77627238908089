/**
 * Inserts `insert` in-between each element of `array`
 * and returns the result as a new array.
 */
export function insertInBetween<T>(insert: T, array: T[]): T[] {
  const result: T[] = [];

  for (const element of array) {
    result.push(element);
    result.push(insert);
  }

  result.pop();

  return result;
}
