import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import type { TextFieldProps } from "@mui/material/TextField";
import type { FilterOptionsState } from "@mui/material/useAutocomplete";
import pDebounce from "p-debounce";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import type { LiHTMLAttributes, ReactNode } from "react";
import type { Account, Api } from "../api";
import { noop } from "../lib/utils";

const FETCH_LIMIT = 64;
const filterOptions = (
  options: Account[],
  { inputValue }: FilterOptionsState<Account>,
) => {
  const inputValueLowerCase = inputValue.toLowerCase();

  return options.filter((option) => {
    if (
      option.name !== undefined &&
      option.name.toLowerCase().startsWith(inputValueLowerCase)
    ) {
      return true;
    }

    const inputValueNumber = +inputValue;

    if (inputValueNumber !== 0 && !isNaN(inputValueNumber)) {
      return (
        option.id === inputValueNumber ||
        (option.forumId !== undefined && option.forumId === inputValueNumber)
      );
    } else {
      return false;
    }
  });
};

interface AccountPickerProps {
  api: Api;
  account: Account | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  label: React.ReactNode;
  margin?: "dense" | "none" | "normal";
  size?: "small" | "medium";
  onChange(account: Account | undefined): void;
}

const getOptionLabel = (option: Account): string =>
  option.name ?? `<${option.id}>`;
const getOptionSelected = (option: Account, value: Account): boolean =>
  option.id === value.id;

export const AccountPicker = memo(function AccountPicker(
  props: AccountPickerProps,
) {
  const { api, account, disabled, fullWidth, label, margin, onChange, size } =
    props;

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<Account[]>([]);

  const fetch = useMemo(
    () =>
      pDebounce(async (inputValue: string): Promise<Account[]> => {
        const queries: Promise<Account[]>[] = [];
        const inputValueNumber = +inputValue;

        if (inputValueNumber !== 0 && !isNaN(inputValueNumber)) {
          queries.push(
            api.getAccounts({
              filters: {
                id: inputValueNumber,
              },
              limit: FETCH_LIMIT,
              sort: [["name", "asc"]],
            }),
          );

          queries.push(
            api.getAccounts({
              filters: {
                forumId: inputValueNumber,
              },
              limit: FETCH_LIMIT,
              sort: [["name", "asc"]],
            }),
          );
        }

        queries.push(
          api.getAccounts({
            filters: {
              nameStartsWith: inputValue,
            },
            limit: FETCH_LIMIT,
            sort: [["name", "asc"]],
          }),
        );

        const results = await Promise.all(queries);

        return results.flat();
      }, 500),
    [api],
  );

  const handleChange = useCallback(
    (event: unknown, newValue: Account | null) => {
      setOptions((options) =>
        newValue !== null ? [newValue, ...options] : options,
      );
      onChange(newValue ?? undefined);
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (event: unknown, newInputValue: string) => {
      setInputValue(newInputValue);
    },
    [],
  );

  const handleRenderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField {...params} fullWidth label={label} />
    ),
    [label],
  );

  const handleRenderOption = useCallback(
    (
      props: LiHTMLAttributes<HTMLLIElement> & { key?: string },
      option: Account,
    ): ReactNode => {
      const { key, ...otherProps } = props;
      const name = option.name;

      if (typeof name === "undefined") {
        return (
          <li key={key} {...otherProps}>
            <span>&lt;ID: {option.id}&gt;</span>
          </li>
        );
      } else {
        const optionEnd = name.substring(inputValue.length);
        const optionStart = name.substring(0, inputValue.length);

        return (
          <li key={key} {...otherProps}>
            <span style={{ fontWeight: 700 }}>{optionStart}</span>
            {<span style={{ fontWeight: 400 }}>{optionEnd}</span>}
          </li>
        );
      }
    },
    [inputValue],
  );

  useEffect(() => {
    let active = true;

    fetch(inputValue)
      ?.then((results: Account[]) => {
        if (active) {
          setOptions(results);
        }
      })
      .catch(noop);

    return () => {
      active = false;
    };
  }, [account, fetch, inputValue]);

  return (
    <FormControl disabled={disabled} fullWidth={fullWidth} margin={margin}>
      <Autocomplete
        autoComplete
        autoHighlight
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        includeInputInList
        isOptionEqualToValue={getOptionSelected}
        onChange={handleChange}
        onInputChange={handleInputChange}
        openOnFocus
        options={options}
        renderInput={handleRenderInput}
        renderOption={handleRenderOption}
        size={size}
        value={account ?? null}
      />
    </FormControl>
  );
});
