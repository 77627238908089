import { memo, useCallback, useLayoutEffect, useMemo } from "react";
import { BehaviorSubject, combineLatest, Observable, of, race } from "rxjs";
import {
  combineLatestWith,
  delay,
  map,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { useObservable } from "../../hooks/useObservable";
import type { InfiniScrollFetcher } from "../../lib/InfiniScroll";
import { infiniScroll } from "../../lib/InfiniScroll";
import type { CellFormatterProps } from "./CellFormatterProps";
import type { Column } from "./Column";
import type { DetailsFormatter } from "./DetailsFormatter";
import type { GenericRow } from "./GenericRow";
import type { InfiniCellFormatterProps } from "./InfiniCellFormatterProps";
import type { InfiniColumn } from "./InfiniColumn";
import { Table } from "./Table";

class InfiniTableState<T extends GenericRow, M> {
  readonly #fetcher$ = new BehaviorSubject<InfiniScrollFetcher<T, M>>(() =>
    of({ data: [], next: undefined }),
  );
  readonly #forceFetch$ = new BehaviorSubject(false);
  readonly #isNearBottom$ = new BehaviorSubject(false);
  readonly #rowOverrides$ = new BehaviorSubject(new Map<T["id"], T>());

  readonly #fetch$ = combineLatest([
    this.#isNearBottom$,
    this.#forceFetch$,
  ]).pipe(map(([isNearBottom, forceFetch]) => isNearBottom || forceFetch));

  readonly rows$: Observable<readonly (T | undefined)[]>;

  constructor(numberOfLoadingPlaceholders: number) {
    this.rows$ = this.#fetcher$.pipe(
      tap(() => {
        this.#forceFetch$.next(true);
      }),
      switchMap((fetcher): Observable<readonly (T | undefined)[]> => {
        const rows$ = infiniScroll<T, M>({
          fetch$: this.#fetch$,
          fetcher,
        })[0].pipe(
          tap(() => {
            this.#forceFetch$.next(false);
          }),
        );

        return race([
          rows$,
          rows$.pipe(
            startWith(
              Array.from<undefined>({ length: numberOfLoadingPlaceholders }),
            ),
            delay(500),
          ),
        ]).pipe(
          tap(() => {
            this.#rowOverrides$.next(new Map());
          }),
        );
      }),
      combineLatestWith(this.#rowOverrides$),
      map(([rows, rowOverrides]) => {
        return rows.map((row) => {
          if (typeof row === "undefined") {
            return undefined;
          } else {
            const rowOverride = rowOverrides.get(row.id);

            if (typeof rowOverride === "undefined") {
              return row;
            } else {
              return rowOverride;
            }
          }
        });
      }),
      startWith(Array.from<undefined>({ length: numberOfLoadingPlaceholders })),
    );
  }

  readonly overrideRow = (row: T): void => {
    const newMap = new Map(this.#rowOverrides$.value);

    newMap.set(row.id, row);

    this.#rowOverrides$.next(newMap);
  };

  readonly setFetcher = (fetcher: InfiniScrollFetcher<T, M>) => {
    this.#fetcher$.next(fetcher);
  };
  readonly setIsNearBottom = (isNearBottom: boolean) => {
    this.#isNearBottom$.next(isNearBottom);
  };
}

interface InfiniTableProps<T extends GenericRow, M> {
  readonly DetailsFormatter?: DetailsFormatter<T>;
  readonly columns: readonly InfiniColumn<T>[];
  readonly fetcher: InfiniScrollFetcher<T, M>;
  readonly numberOfLoadingPlaceholders?: number;
}

function InfiniTableBase<T extends GenericRow, M>(
  props: InfiniTableProps<T, M>,
) {
  const {
    DetailsFormatter,
    columns,
    fetcher,
    numberOfLoadingPlaceholders = 64,
  } = props;
  const state = useMemo(
    () => new InfiniTableState<T, M>(numberOfLoadingPlaceholders),
    [numberOfLoadingPlaceholders],
  );
  const rows = useObservable(state.rows$, []);

  const wrapInfiniCellFormatter = useCallback(
    function wrapInfiniCellFormatter(
      InfiniCellFormatter: (props: InfiniCellFormatterProps<T>) => JSX.Element,
    ): (props: CellFormatterProps<T>) => JSX.Element {
      return (props: CellFormatterProps<T>) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const patchRow = useCallback(
          (patch: Partial<T>): void => {
            state.overrideRow({ ...props.row, ...patch });
          },
          [props.row],
        );

        return InfiniCellFormatter({ ...props, patchRow });
      };
    },
    [state],
  );

  useLayoutEffect(() => {
    state.setFetcher(fetcher);
  }, [fetcher, state]);

  const vanillaColumns: Column<T>[] = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        CellFormatter: wrapInfiniCellFormatter(column.CellFormatter),
      })),
    [columns, wrapInfiniCellFormatter],
  );

  return (
    <Table<T>
      DetailsFormatter={DetailsFormatter}
      columns={vanillaColumns}
      onIsNearBottomChange={state.setIsNearBottom}
      rows={rows}
    />
  );
}

export const InfiniTable = memo(InfiniTableBase) as typeof InfiniTableBase;
