import { useEffect, useMemo } from "react";
import { Subscription } from "rxjs";
import type { Observable } from "rxjs";
import { Layout } from "../Layout";
import type { AccountsTableFilters } from "./AccountsTable";
import { AccountsTable } from "./AccountsTable";
import type { DebouncedUrlParam } from "../../lib/DebouncedUrlParam";
import { createDebouncedUrlParam } from "../../lib/DebouncedUrlParam";
import { identity } from "../../lib/utils";

class SearchParamsAccountsTableFilters implements AccountsTableFilters {
  readonly #forumId: DebouncedUrlParam<number | undefined>;
  readonly #id: DebouncedUrlParam<number | undefined>;
  readonly #name: DebouncedUrlParam<string | undefined>;

  readonly forumId$: Observable<number | undefined>;
  readonly forumIdSync$: Observable<number | undefined>;
  readonly id$: Observable<number | undefined>;
  readonly idSync$: Observable<number | undefined>;
  readonly name$: Observable<string | undefined>;
  readonly nameSync$: Observable<string | undefined>;
  readonly setForumId: (forumId: number | undefined) => void;
  readonly setId: (id: number | undefined) => void;
  readonly setName: (name: string | undefined) => void;

  constructor() {
    const mapFromNumber = (value: number | undefined) =>
      typeof value === "undefined" ? undefined : `${value}`;
    const mapToNumber = (value: string | undefined) =>
      typeof value === "undefined" ? undefined : Number(value);

    this.#forumId = createDebouncedUrlParam(
      "f.forumId",
      mapToNumber,
      mapFromNumber,
    );

    this.forumId$ = this.#forumId.debounced$;
    this.forumIdSync$ = this.#forumId.sync$;
    this.setForumId = this.#forumId.set;

    this.#id = createDebouncedUrlParam("f.id", mapToNumber, mapFromNumber);

    this.id$ = this.#id.debounced$;
    this.idSync$ = this.#id.sync$;
    this.setId = this.#id.set;

    this.#name = createDebouncedUrlParam("f.name", identity, identity);

    this.name$ = this.#name.debounced$;
    this.nameSync$ = this.#name.sync$;
    this.setName = this.#name.set;
  }

  subscribe(): Subscription {
    const subscriptions = new Subscription();

    subscriptions.add(this.#forumId.subscribe());
    subscriptions.add(this.#id.subscribe());
    subscriptions.add(this.#name.subscribe());

    return subscriptions;
  }
}

export const Accounts = () => {
  const filters = useMemo(() => new SearchParamsAccountsTableFilters(), []);

  useEffect(() => {
    const subscription = filters.subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [filters]);

  return (
    <Layout>
      <AccountsTable filters={filters} />
    </Layout>
  );
};
