import type { ReactNode } from "react";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { isSubsetOf } from "../../lib/utils";
import type { Option } from "./Option";

export interface DisplayOption<T> {
  localized: ReactNode;
  selected: boolean;
  value: T;
}

export class PickerState<T> {
  readonly #isEmptyAllowed$ = new BehaviorSubject(false);
  readonly #isMultiSelect$ = new BehaviorSubject(false);
  readonly #options$ = new BehaviorSubject<Option<T>[]>([]);
  readonly #selectedValues$ = new BehaviorSubject<Set<T>>(new Set());

  readonly isValid$: Observable<boolean> = combineLatest([
    this.#isEmptyAllowed$,
    this.#isMultiSelect$,
    this.#options$,
    this.#selectedValues$,
  ]).pipe(
    map(
      ([isEmptyAllowed, isMultiSelect, options, selectedValues]) =>
        (isEmptyAllowed || selectedValues.size !== 0) &&
        (isMultiSelect || selectedValues.size <= 1) &&
        isSubsetOf(selectedValues, new Set(options.map((o) => o.value))),
    ),
  );
  readonly isEmptyAllowed$ = this.#isEmptyAllowed$.asObservable();
  readonly isMultiSelect$ = this.#isMultiSelect$.asObservable();
  readonly displayOptions$: Observable<DisplayOption<T>[]> = combineLatest([
    this.#options$,
    this.#selectedValues$,
  ]).pipe(
    map(([options, selectedValues]) =>
      options.map((option) => ({
        localized: option.localized,
        selected: selectedValues.has(option.value),
        value: option.value,
      })),
    ),
  );
  readonly options$ = this.#options$.asObservable();
  readonly selectedValues$ = this.#selectedValues$.asObservable();

  localizeValues(values: T[]): ReactNode[] {
    const options = this.#options$.value;

    return values.map(
      (value) => options.find((o) => o.value === value)?.localized ?? "???",
    );
  }

  setIsEmptyAllowed(isEmptyAllowed: boolean): void {
    this.#isEmptyAllowed$.next(isEmptyAllowed);
  }

  setIsMultiSelect(isMultiSelect: boolean): void {
    this.#isMultiSelect$.next(isMultiSelect);
  }

  setOptions(options: Option<T>[]): void {
    this.#options$.next(options);
  }

  setSelectedValues(selectedValues: Set<T>): void {
    this.#selectedValues$.next(selectedValues);
  }
}
