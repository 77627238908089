import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import { memo, useCallback, useLayoutEffect, useMemo } from "react";
import type { ReactElement, ReactNode } from "react";
import { v4 as uuid } from "uuid";
import { useObservable } from "../../hooks/useObservable";
import { insertInBetween } from "../../lib/utils";
import type { MultiSelect } from "./MultiSelect";
import type { Options } from "./Options";
import { PickerState } from "./PickerState";
import { pickerStateContext } from "./pickerStateContext";
import type { SingleSelect } from "./SingleSelect";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const DEFAULT_EMPTY = "—";

class IdManager<T> {
  readonly #idToValue = new Map<string, T>();
  readonly #valueToId = new Map<T, string>();

  registerId(value: T): string {
    const id = this.#valueToId.get(value);

    if (id !== undefined) {
      return id;
    } else {
      const newId = uuid();

      this.#idToValue.set(newId, value);
      this.#valueToId.set(value, newId);

      return newId;
    }
  }

  getValue(id: string): T {
    const value = this.#idToValue.get(id);

    if (value === undefined) {
      throw new Error("No value registered for given ID!");
    }

    return value;
  }
}

export interface PickerProps<T> {
  allowEmpty?: boolean;
  autoFocus?: boolean;
  children:
    | ReactElement<
        typeof Options<T> | typeof SingleSelect<T> | typeof MultiSelect<T>
      >
    | ReactElement<
        typeof Options<T> | typeof SingleSelect<T> | typeof MultiSelect<T>
      >[];
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  margin?: "dense" | "normal" | "none";
  renderAsChipSelect?: boolean;
  size?: "small" | "medium";
}

export const Picker = memo(function Picker<T>(props: PickerProps<T>) {
  const optionIdManager = useMemo<IdManager<T>>(() => new IdManager<T>(), []);
  const state = useMemo<PickerState<T>>(() => new PickerState<T>(), []);
  const id = useMemo(() => uuid(), []);
  const labelId = useMemo(() => uuid(), []);

  const {
    allowEmpty = false,
    autoFocus,
    children,
    disabled,
    fullWidth,
    label,
    margin,
    renderAsChipSelect = false,
    size,
  } = props;

  const displayOptions = useObservable(state.displayOptions$, []);
  const isEmptyAllowed = useObservable(state.isEmptyAllowed$, false);
  const isMultiSelect = useObservable(state.isMultiSelect$, false);
  const isValid = useObservable(state.isValid$, false);
  const selectedOptions = useObservable(state.selectedValues$, new Set());

  useLayoutEffect(() => {
    state.setIsEmptyAllowed(allowEmpty);
  }, [allowEmpty, state]);

  const handleNewValue = useCallback(
    (internalValue: string | string[]) => {
      let internalValueArray: string[];

      if (internalValue instanceof Array) {
        internalValueArray = internalValue.filter((value) => value !== "");
      } else {
        internalValueArray = internalValue === "" ? [] : [internalValue];
      }

      state.setSelectedValues(
        new Set(
          internalValueArray.map((value) => optionIdManager.getValue(value)),
        ),
      );
    },
    [optionIdManager, state],
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      const internalValue = event.target.value as string | string[];

      handleNewValue(internalValue);
    },
    [handleNewValue],
  );

  const handleChipSelectChange = useCallback(
    (_: unknown, internalValue: string[]) => {
      handleNewValue(internalValue);
    },
    [handleNewValue],
  );

  const internalValue = useMemo(() => {
    if (isMultiSelect) {
      return [...selectedOptions].map((option) =>
        optionIdManager.registerId(option),
      );
    } else {
      if (selectedOptions.size > 0) {
        const option = [...selectedOptions][0]!;

        return optionIdManager.registerId(option);
      } else {
        return "";
      }
    }
  }, [isMultiSelect, optionIdManager, selectedOptions]);

  const renderSelectedValues = useCallback(
    (internalValue: string | string[]): ReactNode => {
      let internalValueArray: string[];

      if (internalValue instanceof Array) {
        internalValueArray = internalValue.filter((value) => value !== "");
      } else {
        internalValueArray = internalValue === "" ? [] : [internalValue];
      }

      const localizedValues = state.localizeValues(
        internalValueArray.map((value) => optionIdManager.getValue(value)),
      );

      return insertInBetween(", ", localizedValues);
    },
    [optionIdManager, state],
  );

  const handleRenderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField {...params} fullWidth label={label} />
    ),
    [label],
  );

  const getChipPickerOptionLabel = useCallback(
    (internalName: string): string => {
      const option = optionIdManager.getValue(internalName);

      return String(
        displayOptions.find((o) => o.value === option)?.localized ??
          internalName,
      );
    },
    [displayOptions, optionIdManager],
  );

  return (
    <pickerStateContext.Provider value={state as PickerState<unknown>}>
      {isMultiSelect && renderAsChipSelect ? (
        <FormControl disabled={disabled} fullWidth={fullWidth} margin={margin}>
          <Autocomplete
            autoComplete
            autoHighlight
            getOptionLabel={getChipPickerOptionLabel}
            includeInputInList
            multiple
            onChange={handleChipSelectChange}
            openOnFocus
            options={displayOptions.map((option) =>
              optionIdManager.registerId(option.value),
            )}
            renderInput={handleRenderInput}
            size={size}
            value={internalValue as string[]}
          />
        </FormControl>
      ) : (
        <FormControl
          disabled={disabled}
          error={!isValid}
          fullWidth={fullWidth}
          margin={margin}
          size={size}
        >
          <InputLabel id={labelId}>{label}</InputLabel>
          <Select
            autoFocus={autoFocus}
            id={id}
            label={label}
            labelId={labelId}
            multiple={isMultiSelect}
            onChange={handleSelectChange}
            renderValue={renderSelectedValues}
            value={internalValue as string}
          >
            {isEmptyAllowed && !isMultiSelect && (
              <MenuItem value="">{DEFAULT_EMPTY}</MenuItem>
            )}
            {displayOptions.map((option) => {
              const optionId = optionIdManager.registerId(option.value);

              return (
                <MenuItem key={optionId} value={optionId}>
                  {isMultiSelect && <Checkbox checked={option.selected} />}
                  <ListItemText primary={option.localized} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {children}
    </pickerStateContext.Provider>
  );
});
