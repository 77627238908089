import { useTranslation } from "react-i18next";

interface DateFormatterProps {
  value: Date | null | undefined;
}

export const DateFormatter = ({ value }: DateFormatterProps) => {
  const { t } = useTranslation();

  if (typeof value === "undefined" || value === null) {
    return <span>&mdash;</span>;
  } else {
    const formattedValue = t("General.fullDate", { value });

    return <span title={formattedValue}>{formattedValue}</span>;
  }
};
