import classes from "./IdFormatter.module.css";

interface IdFormatterProps {
  value: string | number | null | undefined;
}

export const IdFormatter = (props: IdFormatterProps) => {
  const { value } = props;

  if (typeof value === "undefined" || value === null) {
    return <span className={classes.root}>&mdash;</span>;
  } else {
    const formattedValue = value.toString();

    return (
      <span className={classes.root} title={formattedValue}>
        {formattedValue}
      </span>
    );
  }
};
