import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Api, Deck } from "../../api";
import type { Option } from "../Picker";
import { Options } from "../Picker";

const FETCH_LIMIT = 128;

interface DeckOptionsProps {
  accountId: number | undefined;
  api: Api;
}

export const DeckOptions = (props: DeckOptionsProps) => {
  const { accountId, api } = props;

  const { i18n } = useTranslation();

  const [decks, setDecks] = useState<Deck[] | undefined>(undefined);
  const options: Option<Deck>[] | undefined = useMemo(
    () =>
      decks
        ?.map((deck) => ({
          localized: deck.name,
          value: deck,
        }))
        .sort((lhs, rhs) =>
          lhs.localized.localeCompare(rhs.localized, i18n.language),
        ),
    [decks, i18n.language],
  );

  const fetchDecks = useCallback(async () => {
    if (typeof accountId === "undefined") {
      return undefined;
    }

    const decks = await api.getDecks({
      filters: {
        ownerId: accountId,
      },
      limit: FETCH_LIMIT,
    });

    return decks;
  }, [api, accountId]);

  useEffect(() => {
    fetchDecks().then((decks) => {
      setDecks(decks);
    });
  }, [fetchDecks]);

  if (options !== undefined) {
    return <Options options={options} />;
  } else {
    return null;
  }
};
