import type { Account } from "../../../api";
import { AccountLink } from "../../AccountLink";

interface AccountFormatterProps {
  value: Account | null | undefined;
}
export const AccountFormatter = (props: AccountFormatterProps) => {
  const { value } = props;

  if (typeof value === "undefined" || value === null) {
    return <span>&mdash;</span>;
  } else {
    return <AccountLink account={value} />;
  }
};
