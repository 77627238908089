import MenuItem from "@mui/material/MenuItem";
import { forwardRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FlagIcon } from "../../../components/FlagIcon";
import { getCountry, getName, isI18nProvided, Language } from "../../../i18n";
import { noop } from "../../../lib/utils";
import classes from "./LanguageSelectionItem.module.css";

interface LanguageSelectionItemProps {
  language: Language;
}

export const LanguageSelectionItem = forwardRef<
  HTMLLIElement,
  LanguageSelectionItemProps
>(function LanguageSelectionItem(props, ref) {
  const { language } = props;
  const { i18n } = useTranslation();

  const handleClick = useCallback(() => {
    if (isI18nProvided(i18n)) {
      i18n.changeLanguage(language).catch(noop);
    }
  }, [i18n, language]);

  const selected = isI18nProvided(i18n) && i18n.language === language;
  const country = getCountry(language);

  return (
    <MenuItem onClick={handleClick} ref={ref} selected={selected}>
      <FlagIcon className={classes.flag} code={country} />
      {getName(language)}
    </MenuItem>
  );
});
