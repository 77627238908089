import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BanStatus } from "../../api";
import { localizeBanStatus } from "../../lib/localizeHelper";
import type { Option } from "../Picker";
import { Options } from "../Picker";

export const BanStatusOptions = () => {
  const { i18n, t } = useTranslation();
  const options: Option<BanStatus>[] = useMemo(
    () =>
      Object.values(BanStatus)
        .map((value) => ({
          localized: localizeBanStatus(t, value),
          value,
        }))
        .sort((lhs, rhs) =>
          lhs.localized.localeCompare(rhs.localized, i18n.language),
        ),
    [i18n.language, t],
  );

  return <Options options={options} />;
};
