"use strict";
// tslint:disable
/**
 * @skylords/api
 * Api server for the Skylords Reborn project
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Configuration = void 0;
class Configuration {
    /**
     * parameter for apiKey security
     * @param name security name
     * @memberof Configuration
     */
    apiKey;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    username;
    /**
     * parameter for basic security
     *
     * @type {string}
     * @memberof Configuration
     */
    password;
    /**
     * parameter for oauth2 security
     * @param name security name
     * @param scopes oauth2 scope
     * @memberof Configuration
     */
    accessToken;
    /**
     * override base path
     *
     * @type {string}
     * @memberof Configuration
     */
    basePath;
    constructor(param = {}) {
        this.apiKey = param.apiKey;
        this.username = param.username;
        this.password = param.password;
        this.accessToken = param.accessToken;
        this.basePath = param.basePath;
    }
}
exports.Configuration = Configuration;
