import type { AccountRank } from "../../../api";
import { AccountRankRenderer } from "../../AccountRankRenderer";

interface AccountRankFormatterProps {
  value: AccountRank | null | undefined;
}

export const AccountRankFormatter = ({ value }: AccountRankFormatterProps) => {
  if (typeof value === "undefined" || value === null) {
    return <span>&mdash;</span>;
  } else {
    return <AccountRankRenderer value={value} />;
  }
};
