import { BehaviorSubject, defer, Observable } from "rxjs";
import {
  endWith,
  exhaustMap,
  filter,
  last,
  scan,
  shareReplay,
  takeUntil,
  tap,
} from "rxjs/operators";
import type { InfiniScrollFetcher } from "./InfiniScrollFetcher";

interface InfiniScrollOptions<T, M> {
  readonly fetch$: Observable<boolean>;
  readonly fetcher: InfiniScrollFetcher<T, M>;
}

type InfiniScrollResult<T> = [
  result$: Observable<readonly T[]>,
  isFetching$: Observable<boolean>,
];

export function infiniScroll<T, M>(
  options: InfiniScrollOptions<T, M>,
): InfiniScrollResult<T> {
  const { fetch$, fetcher } = options;
  const isFetching$ = new BehaviorSubject(false);
  const end$ = fetch$.pipe(
    filter(() => false),
    endWith(false),
  );

  const data$ = defer(() => {
    let isFirstFetch = true;
    let nextMarker: M | undefined = undefined;

    isFetching$.next(false);

    return fetch$.pipe(
      filter((fetch) => fetch),
      tap(() => {
        isFetching$.next(true);
      }),
      exhaustMap(() => fetcher(nextMarker).pipe(last())),
      tap(({ next }) => {
        isFetching$.next(false);
        nextMarker = next;
      }),
      filter(({ data }) => data.length > 0 || isFirstFetch),
      scan((acc, { data }) => [...acc, ...data], [] as T[]),
      tap(() => {
        isFirstFetch = false;
      }),
    );
  }).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  return [data$, isFetching$.pipe(takeUntil(end$))];
}
