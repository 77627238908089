import ExitToApp from "@mui/icons-material/ExitToApp";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useStoredApiToken } from "../../store/apiToken";

export const LogoutButton = () => {
  const { t } = useTranslation();

  const [apiToken, setApiToken] = useStoredApiToken();
  const isLoggedIn = typeof apiToken !== "undefined";

  const handleClick = useCallback(() => {
    setApiToken(undefined);
  }, [setApiToken]);

  return (
    <Tooltip title={t("LogoutButton.logout") as string}>
      <div>
        <IconButton disabled={!isLoggedIn} onClick={handleClick}>
          <ExitToApp />
        </IconButton>
      </div>
    </Tooltip>
  );
};
