import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";

interface RefreshErrorHandlerProps {
  error: unknown;
}

export const RefreshErrorHandler = ({ error }: RefreshErrorHandlerProps) => {
  const handleReset = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <>
      <Typography>
        An unexpected error, which could not be recovered from, occured!
      </Typography>
      <pre>{String(error)}</pre>
      <Button onClick={handleReset}>Reset App</Button>
    </>
  );
};
