import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Bfp.module.css";
import { BfpIcon } from "./BfpIcon";

interface BfpProps {
  fullWidth?: boolean;
  value: number | null | undefined;
}

export const Bfp = (props: BfpProps) => {
  const { fullWidth, value } = props;

  const { t } = useTranslation();
  const formattedValue = useMemo(() => {
    if (typeof value === "undefined" || value === null) {
      return undefined;
    } else {
      return String(t("Bfp.value", { value: Math.floor(value) }));
    }
  }, [t, value]);
  const formattedExactValue = useMemo(() => {
    if (typeof value === "undefined" || value === null) {
      return undefined;
    } else {
      return String(t("Bfp.exactValue", { value }));
    }
  }, [t, value]);

  return (
    <span
      className={clsx(classes.root, {
        [classes.fullWidth]: fullWidth,
        [classes.negative]: (value ?? 0) < 0,
      })}
    >
      {typeof formattedValue === "undefined" ? (
        <span>&mdash;</span>
      ) : (
        <span className={classes.label} title={formattedExactValue}>
          {formattedValue}&nbsp;
        </span>
      )}
      <BfpIcon />
    </span>
  );
};
