import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { forwardRef, useCallback, useState } from "react";
import type { UseTranslationResponse } from "react-i18next";
import { useTranslation } from "react-i18next";
import { BoundMenu } from "../../../../../components/BoundMenu";
import { assertNever } from "../../../../../lib/utils";
import { HwidMatchMode } from "./HwidMatchMode";
import classes from "./HwidMatchModePicker.module.css";

const hwidMatchModes = Object.values(HwidMatchMode);

function hwidMatchModeIcon(hwidMatchMode: HwidMatchMode): JSX.Element {
  switch (hwidMatchMode) {
    case HwidMatchMode.Lax:
      return <AlignHorizontalCenterIcon />;
    case HwidMatchMode.Postfix:
      return <AlignHorizontalLeftIcon />;
    case HwidMatchMode.Prefix:
      return <AlignHorizontalRightIcon />;
    case HwidMatchMode.Strict:
      return <DragHandleIcon />;
    default:
      throw assertNever(hwidMatchMode);
  }
}

function localizeHwidMatchMode(
  t: UseTranslationResponse<string, undefined>[0],
  hwidMatchMode: HwidMatchMode,
): string {
  switch (hwidMatchMode) {
    case HwidMatchMode.Lax:
      return t("HwidMatchModePickerItem.lax");
    case HwidMatchMode.Postfix:
      return t("HwidMatchModePickerItem.postfix");
    case HwidMatchMode.Prefix:
      return t("HwidMatchModePickerItem.prefix");
    case HwidMatchMode.Strict:
      return t("HwidMatchModePickerItem.strict");
    default:
      throw assertNever(hwidMatchMode);
  }
}

interface HwidMatchModePickerItemProps {
  hwidMatchMode: HwidMatchMode;
  onClick: () => void;
  selected: boolean;
}

const HwidMatchModePickerItem = forwardRef<
  HTMLLIElement,
  HwidMatchModePickerItemProps
>(function HwidMatchModePickerItem(props, ref) {
  const { hwidMatchMode, onClick, selected } = props;
  const { t } = useTranslation();

  return (
    <MenuItem onClick={onClick} ref={ref} selected={selected}>
      <span className={classes.icon}>{hwidMatchModeIcon(hwidMatchMode)}</span>
      {localizeHwidMatchMode(t, hwidMatchMode)}
    </MenuItem>
  );
});

interface HwidMatchModePickerProps {
  onChange: (value: HwidMatchMode) => void;
  value: HwidMatchMode;
}

export const HwidMatchModePicker = (props: HwidMatchModePickerProps) => {
  const { onChange, value } = props;
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [target, setTarget] = useState<HTMLButtonElement | null>(null);

  const handleOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  return (
    <>
      <Tooltip title={t("HwidMatchModePicker.selectHwidMatchMode") as string}>
        <IconButton onClick={handleOpen} ref={setTarget}>
          {hwidMatchModeIcon(value)}
        </IconButton>
      </Tooltip>
      {target !== null && (
        <BoundMenu onClose={handleClose} open={menuOpen} target={target}>
          {hwidMatchModes.map((hwidMatchMode) => (
            <HwidMatchModePickerItem
              hwidMatchMode={hwidMatchMode}
              key={hwidMatchMode}
              onClick={() => onChange(hwidMatchMode)}
              selected={hwidMatchMode === value}
            />
          ))}
        </BoundMenu>
      )}
    </>
  );
};
