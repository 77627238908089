import goldIconPng from "../assets/GoldIcon.png";
import goldIconWebp from "../assets/GoldIcon.webp";

import { createBitmapIcon } from "./createBitmapIcon";

export const GoldIcon = createBitmapIcon({
  alt: "Gold",
  displayName: "GoldIcon",
  src: goldIconPng,
  srcWebp: goldIconWebp,
});
