import bfpIconPng from "../assets/BfpIcon.png";
import bfpIconWebp from "../assets/BfpIcon.webp";

import { createBitmapIcon } from "./createBitmapIcon";

export const BfpIcon = createBitmapIcon({
  alt: "Bfp",
  displayName: "BfpIcon",
  src: bfpIconPng,
  srcWebp: bfpIconWebp,
});
