import { useEffect, useState } from "react";
import type { Observable } from "rxjs";

export function useObservable<T>(observable$: Observable<T>, init: T): T {
  const [value, setValue] = useState(() => init);

  useEffect(() => {
    const subscription = observable$.subscribe((value) => {
      setValue(() => value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [observable$]);

  return value;
}
