import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Api, ConnectionLog } from "../../api";
import type { Option } from "../Picker";
import { Options } from "../Picker";

const FETCH_LIMIT = 128;

interface Ip {
  ip: string;
  lastUsed: Date;
}

function connectionLogsToIps(connectionLogs: ConnectionLog[]): Ip[] {
  const ipMap = new Map<string, Date>();

  for (const connectionLog of connectionLogs) {
    const { ip, lastUsed } = connectionLog;
    const oldLastUsed = ipMap.get(ip);

    if (typeof oldLastUsed === "undefined" || oldLastUsed < lastUsed) {
      ipMap.set(ip, lastUsed);
    }
  }

  const ips: Ip[] = [];

  for (const [ip, lastUsed] of ipMap.entries()) {
    ips.push({ ip, lastUsed });
  }

  ips.sort((lhs, rhs) => rhs.lastUsed.valueOf() - lhs.lastUsed.valueOf());

  return ips;
}

interface IpOptionsProps {
  accountId: number | undefined;
  api: Api;
}

export const IpOptions = (props: IpOptionsProps) => {
  const { accountId, api } = props;

  const { t } = useTranslation();

  const [ips, setIps] = useState<Ip[] | undefined>(undefined);
  const options: Option<string>[] | undefined = useMemo(
    () =>
      ips?.map((ip) => ({
        localized: `${ip.ip} (${t("General.fullDate", {
          value: ip.lastUsed,
        })})`,
        value: ip.ip,
      })),
    [ips, t],
  );

  const fetchIps = useCallback(async () => {
    if (typeof accountId === "undefined") {
      return undefined;
    }

    const connectionLogs = await api.getConnectionLogs({
      filters: {
        accountId,
      },
      limit: FETCH_LIMIT,
      sort: [["lastUsed", "desc"]],
    });

    return connectionLogsToIps(connectionLogs);
  }, [api, accountId]);

  useEffect(() => {
    fetchIps().then((ips) => {
      setIps(ips);
    });
  }, [fetchIps]);

  if (options !== undefined) {
    return <Options options={options} />;
  } else {
    return null;
  }
};
