import classes from "./NoFilter.module.css";

interface NoFilterProps {
  readonly label: string;
}

export const NoFilter = (props: NoFilterProps) => {
  const { label } = props;

  return <span className={classes.root}>{label}</span>;
};
