import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { defer } from "rxjs";
import { useApi } from "../../../../../api";
import type { ConnectionLog } from "../../../../../api";
import {
  AccountFormatter,
  DateFormatter,
  InfiniTable,
  StringFormatter,
} from "../../../../../components/Table";
import type { InfiniColumn } from "../../../../../components/Table";
import { NoFilter } from "../../../../../components/TableFilters";
import { assertNever } from "../../../../../lib/utils";
import { HwidMatchMode } from "./HwidMatchMode";
import type { MultiAccountRow } from "./MultiAccountRow";

const BATCH_SIZE = 64;

function apiMultiAccountToRow(connectionLogs: ConnectionLog): MultiAccountRow {
  return {
    account: connectionLogs.account,
    hwid: connectionLogs.hwid,
    id: `${connectionLogs.accountId}:${connectionLogs.hwid}:${connectionLogs.ip}`,
    ip: connectionLogs.ip,
    lastUsed: connectionLogs.lastUsed,
  };
}

function hwidMatchesStricterHwidMatchMode(
  lhsHwid: string,
  rhsHwid: string,
  hwidMatchMode: HwidMatchMode,
): boolean {
  switch (hwidMatchMode) {
    case HwidMatchMode.Lax:
      // no need to check, since this is the server-side match mode
      return true;
    case HwidMatchMode.Postfix:
      return lhsHwid.substring(18) === rhsHwid.substring(18);
    case HwidMatchMode.Prefix:
      return lhsHwid.substring(0, 17) === rhsHwid.substring(0, 17);
    case HwidMatchMode.Strict:
      return lhsHwid === rhsHwid;
    default:
      throw assertNever(hwidMatchMode);
  }
}

interface MultiAccountsTableProps {
  hwid: string | undefined;
  hwidMatchMode: HwidMatchMode;
  ip: string | undefined;
}

export const MultiAccountsTable = (props: MultiAccountsTableProps) => {
  const { hwid, hwidMatchMode, ip } = props;
  const { t } = useTranslation();
  const { api } = useApi();

  const columns = useMemo(
    (): InfiniColumn<MultiAccountRow>[] => [
      {
        id: "account",
        CellFormatter: ({ row }) => <AccountFormatter value={row.account} />,
        HeaderFormatter: () => (
          <NoFilter label={t("AccountActionsMultiAccount.account")} />
        ),
        width: 200,
      },
      {
        id: "hwid",
        CellFormatter: ({ row }) => <StringFormatter value={row.hwid} />,
        HeaderFormatter: () => (
          <NoFilter label={t("AccountActionsMultiAccount.hwid")} />
        ),
        width: 350,
      },
      {
        id: "ip",
        CellFormatter: ({ row }) => <StringFormatter value={row.ip} />,
        HeaderFormatter: () => (
          <NoFilter label={t("AccountActionsMultiAccount.ip")} />
        ),
        width: 150,
      },
      {
        id: "lastUsed",
        CellFormatter: ({ row }) => <DateFormatter value={row.lastUsed} />,
        HeaderFormatter: () => (
          <NoFilter label={t("AccountActionsMultiAccount.lastUsed")} />
        ),
        width: 180,
      },
    ],
    [t],
  );

  const fetcher = useMemo(() => {
    const seenAccounts = new Set();

    return (offset = 0) =>
      defer(async () => {
        const data = await api.getConnectionLogs({
          filters: {
            hwidMatch: hwid,
            ip,
          },
          limit: BATCH_SIZE,
          offset,
        });

        const filteredData = data.filter((row) => {
          if (
            !seenAccounts.has(row.accountId) &&
            (typeof hwid === "undefined" ||
              hwidMatchesStricterHwidMatchMode(row.hwid, hwid, hwidMatchMode))
          ) {
            seenAccounts.add(row.accountId);
            return true;
          } else {
            return false;
          }
        });

        return {
          data: filteredData.map((data) => apiMultiAccountToRow(data)),
          next: offset + data.length,
        };
      });
  }, [api, hwid, hwidMatchMode, ip]);

  return (
    <InfiniTable
      columns={columns}
      fetcher={fetcher}
      numberOfLoadingPlaceholders={3}
    />
  );
};
