import CircularProgress from "@mui/material/CircularProgress";
import classes from "./LoadingBlock.module.css";

export const LoadingBlock = () => {
  return (
    <div className={classes.root}>
      <div>
        <CircularProgress size={75} />
      </div>
    </div>
  );
};
