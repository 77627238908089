import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBox from "@mui/icons-material/IndeterminateCheckBox";
import classes from "./BooleanFormatter.module.css";

interface BooleanFormatterProps {
  value: boolean | null | undefined;
}

export const BooleanFormatter = (props: BooleanFormatterProps) => {
  const { value } = props;

  if (typeof value === "undefined" || value === null) {
    return <IndeterminateCheckBox className={classes.root} />;
  } else {
    return value ? (
      <CheckBox className={classes.root} />
    ) : (
      <CheckBoxOutlineBlank className={classes.root} />
    );
  }
};
