import Delete from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { Trans, useTranslation } from "react-i18next";
import type { AccountRow } from "../AccountRow";
import { AccountActionsConfirmDialog } from "./AccountActionsConfirmDialog";

interface AccountActionsDeleteProps {
  account: AccountRow;
  onClose: () => void;
  onDelete: (account: AccountRow) => Promise<void>;
  open: boolean;
}

export const AccountActionsDelete = (props: AccountActionsDeleteProps) => {
  const { account, onClose, onDelete, open } = props;
  const { t } = useTranslation();

  const { name } = account;

  return (
    <AccountActionsConfirmDialog
      account={account}
      confirmIcon={<Delete />}
      locals={{
        cancel: t("AccountActionsDelete.cancel"),
        confirm: t("AccountActionsDelete.confirm"),
        confirmName: t("AccountActionsDelete.confirmName"),
        info: t("AccountActionsDelete.info"),
        title: t("AccountActionsDelete.title"),
      }}
      onClose={onClose}
      onConfirm={onDelete}
      open={open}
    >
      <Typography>
        <Trans
          components={{ b: <strong /> }}
          i18nKey="AccountActionsDelete.info"
          values={{ name }}
        />
      </Typography>
      <Alert severity="error">
        {t("AccountActionsDelete.warningIrreversible")}
      </Alert>
      <Alert severity="warning">
        {t("AccountActionsDelete.hintAdditionalProcedures")}
      </Alert>
      <Alert severity="info">
        {t("AccountActionsDelete.hintOnlyLeadModerators")}
      </Alert>
    </AccountActionsConfirmDialog>
  );
};
