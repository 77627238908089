import { useTranslation } from "react-i18next";
import { AccountRank } from "../api";
import { assertNever } from "../lib/utils";

interface AccountRankRendererProps {
  value: AccountRank;
}
export function AccountRankRenderer(props: AccountRankRendererProps) {
  const { value } = props;
  const { t } = useTranslation();

  let rank: string;

  switch (value) {
    case AccountRank.Bot:
      rank = String(t("AccountRanks.bot"));
      break;

    case AccountRank.Normal:
      rank = String(t("AccountRanks.normal"));
      break;

    case AccountRank.StaffAdmin:
      rank = String(t("AccountRanks.staffAdmin"));
      break;

    case AccountRank.StaffArtist:
      rank = String(t("AccountRanks.staffArtist"));
      break;

    case AccountRank.StaffCommunityManager:
      rank = String(t("AccountRanks.staffCommunityManager"));
      break;

    case AccountRank.StaffDesigner:
      rank = String(t("AccountRanks.staffDesigner"));
      break;

    case AccountRank.StaffDeveloper:
      rank = String(t("AccountRanks.staffDeveloper"));
      break;

    case AccountRank.StaffEventOrganizer:
      rank = String(t("AccountRanks.staffEventOrganizer"));
      break;

    case AccountRank.StaffGeneral:
      rank = String(t("AccountRanks.staffGeneral"));
      break;

    case AccountRank.StaffLeadDeveloper:
      rank = String(t("AccountRanks.staffLeadDeveloper"));
      break;

    case AccountRank.StaffMarketeer:
      rank = String(t("AccountRanks.staffMarketeer"));
      break;

    case AccountRank.StaffModerator:
      rank = String(t("AccountRanks.staffModerator"));
      break;

    case AccountRank.StaffProjectCoordinator:
      rank = String(t("AccountRanks.staffProjectCoordinator"));
      break;

    default:
      throw assertNever(value);
  }

  return <span title={rank}>{rank}</span>;
}
