import "../../i18n";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { ReactNode } from "react";
import { Suspense } from "react";
import { LoadingBlock } from "../../components/LoadingBlock";
import { ToolbarLogo } from "../../components/ToolbarLogo";
import { useStoredApiToken } from "../../store/apiToken";
import { LoginDialog } from "../LoginDialog";
import { Navigation } from "../Navigation";
import { LanguageSelection } from "./LanguageSelection";
import classes from "./Layout.module.css";
import { LogoutButton } from "./LogoutButton";
import { ThemePicker } from "./ThemePicker";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout = (props: LayoutProps) => {
  const { children } = props;

  const showLogo = useMediaQuery("(min-width:700px)");
  const [apiToken] = useStoredApiToken();
  const isValidSession = typeof apiToken !== "undefined";

  return (
    <div className={classes.grid}>
      <AppBar className={classes.appbar} color="inherit" position="static">
        <Toolbar>
          {showLogo && <ToolbarLogo className={classes.logo} />}
          {isValidSession && <Navigation />}
          <div className={classes.grow} />
          <LanguageSelection />
          <ThemePicker />
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <main className={classes.main}>
        <Suspense fallback={<LoadingBlock />}>
          <LoginDialog open={!isValidSession} />
          <Suspense fallback={<LoadingBlock />}>
            {isValidSession && children}
          </Suspense>
        </Suspense>
      </main>
    </div>
  );
};
