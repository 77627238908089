import { useLayoutEffect } from "react";
import { distinctUntilChanged, Observable, skip } from "rxjs";

/**
 * Calls a function for every change to the observables value.
 * Does *not* call the function immediately (in case of a `BehaviorSubject`, for example).
 *
 * **Does not support dynamic input parameters!**
 */
export function useObservableOnChange<T>(
  observable$: Observable<T>,
  onChange: (value: T) => void,
) {
  useLayoutEffect(() => {
    const subscription = observable$
      .pipe(distinctUntilChanged(), skip(1))
      .subscribe((value) => {
        onChange(value);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [onChange, observable$]);
}
