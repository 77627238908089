import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableHead from "@mui/material/TableHead";
import clsx from "clsx";
import { memo, useCallback, useLayoutEffect, useMemo, useState } from "react";
import { BehaviorSubject, fromEvent, of } from "rxjs";
import {
  combineLatestWith,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
} from "rxjs/operators";
import { noop } from "../../lib/utils";
import type { Column } from "./Column";
import type { DetailsFormatter } from "./DetailsFormatter";
import type { GenericRow } from "./GenericRow";
import classes from "./Table.module.css";
import { Placeholder } from "./utils";

const NEAR_BOTTOM_THRESHOLD = 1000;

interface TableRowProps<T extends GenericRow> {
  readonly DetailsFormatter?: DetailsFormatter<T>;
  readonly columns: readonly Column<T>[];
  readonly dynamicHeight: boolean;
  readonly row: T | undefined;
}

function TableRowBase<T extends GenericRow>(props: TableRowProps<T>) {
  const { DetailsFormatter, columns, dynamicHeight, row } = props;
  const [isDetailsInDom, setIsDetailsInDom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDetailsClick = useCallback(() => {
    setIsDetailsInDom(true);
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <div className={clsx(classes.row, { [classes.detailsOpen]: isOpen })}>
      {typeof DetailsFormatter !== "undefined" && (
        <MuiTableCell
          className={clsx(classes.cell, classes.toggleDetails)}
          component="div"
        >
          <IconButton onClick={handleToggleDetailsClick} size="small">
            <ExpandMoreIcon />
          </IconButton>
        </MuiTableCell>
      )}

      {columns.map((column) => {
        const { CellFormatter } = column;

        return (
          <MuiTableCell
            className={clsx(classes.cell, {
              [classes.dynamicHeight]: dynamicHeight,
            })}
            component="div"
            key={column.id}
          >
            {typeof row === "undefined" ? (
              <Placeholder />
            ) : (
              <CellFormatter row={row} />
            )}
          </MuiTableCell>
        );
      })}

      {isDetailsInDom &&
        typeof DetailsFormatter !== "undefined" &&
        typeof row !== "undefined" && (
          <div className={classes.detailsRow}>
            <MuiTableCell component="div"></MuiTableCell>
            <MuiTableCell className={classes.detailsCell} component="div">
              <DetailsFormatter row={row} />
            </MuiTableCell>
          </div>
        )}
    </div>
  );
}

const TableRow = memo(TableRowBase) as typeof TableRowBase;

class TableState {
  readonly #tableElement$ = new BehaviorSubject<HTMLDivElement | null>(null);
  readonly #refresh$ = new BehaviorSubject<undefined>(undefined);

  readonly isNearBottom$ = this.#tableElement$.pipe(
    switchMap((table) => {
      if (table === null) {
        return of(false);
      } else {
        return fromEvent(table, "scroll").pipe(
          startWith(null),
          combineLatestWith(this.#refresh$),
          map(() => {
            const { clientHeight, scrollHeight, scrollTop } = table;
            const isNearBottom =
              scrollTop + clientHeight > scrollHeight - NEAR_BOTTOM_THRESHOLD;

            return isNearBottom;
          }),
        );
      }
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly handleRender = () => {
    this.#refresh$.next(undefined);
  };
  readonly setTableElement = (table: HTMLDivElement | null) => {
    this.#tableElement$.next(table);
  };
}

interface TableProps<T extends GenericRow> {
  readonly DetailsFormatter?: DetailsFormatter<T>;
  readonly columns: readonly Column<T>[];
  readonly dynamicRowHeight?: boolean;
  readonly rows: readonly (T | undefined)[];
  onIsNearBottomChange?(isNearBottom: boolean): void;
}

function TableBase<T extends GenericRow>(props: TableProps<T>) {
  const {
    DetailsFormatter,
    columns,
    dynamicRowHeight = false,
    onIsNearBottomChange,
    rows,
  } = props;
  const state = useMemo(() => new TableState(), []);

  const gridTemplateColumns = useMemo(
    () =>
      (typeof DetailsFormatter === "undefined" ? "" : "46px ") +
      columns
        .map((column) =>
          typeof column.width === "string" ? column.width : `${column.width}px`,
        )
        .join(" "),
    [DetailsFormatter, columns],
  );

  useLayoutEffect(() => {
    state.handleRender();
  });

  useLayoutEffect(() => {
    if (typeof onIsNearBottomChange === "undefined") {
      return noop;
    } else {
      const subscription = state.isNearBottom$.subscribe((isNearBottom) => {
        onIsNearBottomChange(isNearBottom);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [onIsNearBottomChange, state]);

  return (
    <MuiTable
      className={classes.root}
      component="div"
      ref={state.setTableElement}
      size="small"
      stickyHeader
    >
      <MuiTableHead
        className={classes.head}
        component="div"
        style={{ gridTemplateColumns }}
      >
        {typeof DetailsFormatter !== "undefined" && (
          <MuiTableCell className={classes.cell} component="div"></MuiTableCell>
        )}
        {columns.map((column) => {
          const { HeaderFormatter } = column;

          return (
            <MuiTableCell
              className={classes.cell}
              component="div"
              key={column.id}
            >
              <HeaderFormatter />
            </MuiTableCell>
          );
        })}
      </MuiTableHead>
      <MuiTableBody
        className={classes.body}
        component="div"
        style={{ gridTemplateColumns }}
      >
        {rows.map((row, index) => {
          const rowKey =
            typeof row === "undefined"
              ? `placeholder_${index}`
              : `id_${row.id}`;

          return (
            <TableRow
              DetailsFormatter={DetailsFormatter}
              columns={columns}
              dynamicHeight={dynamicRowHeight}
              key={rowKey}
              row={row}
            />
          );
        })}
      </MuiTableBody>
    </MuiTable>
  );
}

export const Table = memo(TableBase) as typeof TableBase;
