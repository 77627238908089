import Menu from "@mui/material/Menu";
import type { MenuProps } from "@mui/material/Menu";
import { useState } from "react";
import type { ReactNode } from "react";
import { v4 as uuid } from "uuid";

interface BoundMenuProps extends Omit<MenuProps, "anchorEl" | "id"> {
  target: HTMLButtonElement;
  children?: ReactNode;
}

export const BoundMenu = ({ target, ...restProps }: BoundMenuProps) => {
  const [menuId] = useState(() => uuid());

  target.setAttribute("aria-haspopup", "true");
  target.setAttribute("aria-owns", menuId);

  return <Menu {...restProps} anchorEl={target} id={menuId} />;
};
