import classes from "./StringFormatter.module.css";

interface StringFormatterProps {
  value: string | null | undefined;
}

export const StringFormatter = ({ value }: StringFormatterProps) => {
  if (typeof value === "undefined" || value === null) {
    return <span className={classes.root}>&mdash;</span>;
  } else {
    return (
      <input className={classes.root} readOnly title={value} value={value} />
    );
  }
};
