import Link from "@mui/material/Link";
import classes from "./ForumIdFormatter.module.css";

interface ForumIdFormatterProps {
  value: number | null | undefined;
}

export const ForumIdFormatter = (props: ForumIdFormatterProps) => {
  const { value } = props;

  if (typeof value === "undefined" || value === null) {
    return <span className={classes.root}>&mdash;</span>;
  } else {
    const formattedValue = value.toString();

    return (
      <Link
        className={classes.root}
        href={`https://forum.skylords.eu/index.php?/profile/${value}-0/`}
        rel="noopener noreferrer"
        target="_blank"
        title={formattedValue}
      >
        {value}
      </Link>
    );
  }
};
